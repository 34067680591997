import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'shared/model/device.model';
import DeliveryDialog from './deliveryDialog';

interface IDeliveryBtnProps {
  device: IDevice;
}

const DeliveryBtn = ({ device }: IDeliveryBtnProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <DeliveryDialog device={device} open={open} handleClose={() => setOpen(false)} />}
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {t('orders.another')}
      </Button>
    </>
  );
};

export default DeliveryBtn;
