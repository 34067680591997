import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'shared/model/device.model';
import CalibrateDialog from './calibrateDialog';

interface ICalibrateBtnProps {
  device: IDevice;
}

const CalibrateBtn = ({ device }: ICalibrateBtnProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <CalibrateDialog device={device} open={open} handleClose={() => setOpen(false)} />}
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {t('calibrate')}
      </Button>
    </>
  );
};

export default CalibrateBtn;
