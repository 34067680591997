import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import {
  AutoOrder,
  AutoOrderHistory,
  AutoOrderResponse,
  CustomIntegration,
  DeviceAutoOrder,
  GroupAutoOrder,
  groupAutoOrdersByGroup,
  normalizeMaterialNo,
  parseAutoOrderResponse
} from 'shared/model/autoOrder.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { errorNotification } from './notifierSlice';
import { Moment } from 'moment';

const initialState = {
  loading: false,
  errorMessage: '',
  groupAutoOrders: [] as GroupAutoOrder[],
  autoOrder: null as AutoOrder | null,
  autoOrderHistory: [] as AutoOrderHistory[],
  updating: false,
  updateSuccess: false
};

export type autoOrderState = typeof initialState;

export const slice = createSlice({
  name: 'autoOrders',
  initialState,
  reducers: {
    // General update state
    loadStart: state => {
      state.loading = true;
      state.errorMessage = '';
    },
    loadFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    updateStart: state => {
      state.updating = true;
      state.updateSuccess = false;
    },
    updateFailed: (state, action: PayloadAction<string>) => {
      state.updateSuccess = false;
      state.updating = false;
      state.errorMessage = action.payload;
    },

    fetchAutoOrdersSuccess: (state, action: PayloadAction<AutoOrder[]>) => {
      state.loading = false;
      state.groupAutoOrders = groupAutoOrdersByGroup(action.payload);
    },
    fetchAutoOrdersHistorySuccess: (state, action: PayloadAction<AutoOrderHistory[]>) => {
      state.loading = false;
      state.autoOrderHistory = action.payload;
    },
    updateAutoOrdersSuccess: (
      state,
      {
        payload: { autoOrders, mode }
      }: PayloadAction<{
        autoOrders: AutoOrder[];
        mode: 'patch' | 'replace';
      }>
    ) => {
      state.updating = false;
      state.updateSuccess = true;
      state.groupAutoOrders =
        mode === 'patch'
          ? patchInGroupAutoOrders(state.groupAutoOrders, autoOrders)
          : groupAutoOrdersByGroup(autoOrders);
    },

    /**
     * Activate/deactivate auto-orders optimistically while waiting for the server response.
     */
    optimisticActivate: (
      state,
      {
        payload
      }: PayloadAction<{ device_reference?: string; group_id?: string; is_active: boolean }>
    ) => {
      for (const groupAutoOrder of state.groupAutoOrders) {
        const shouldUpdateGroup = groupAutoOrder.group_id === payload.group_id;

        for (const deviceAutoOrder of groupAutoOrder.device_auto_orders) {
          const shouldUpdateDevice =
            shouldUpdateGroup || deviceAutoOrder.device_reference === payload.device_reference;
          if (shouldUpdateDevice) {
            deviceAutoOrder.is_active = payload.is_active;

            for (const alert of deviceAutoOrder.alerts) {
              alert.is_active = payload.is_active;
            }
          }
        }

        const isGroupActive = groupAutoOrder.device_auto_orders.some(
          deviceAutoOrder => deviceAutoOrder.is_active
        );
        groupAutoOrder.is_active = isGroupActive;
      }
    },

    deleteGroupAutoOrderSuccess: (state, { payload: groupId }: PayloadAction<string>) => {
      state.updating = false;
      state.groupAutoOrders = state.groupAutoOrders.filter(
        groupAutoOrder => groupAutoOrder.group_id !== groupId
      );
    }
  }
});

export default slice.reducer;

//Actions
const {
  loadStart,
  loadFailed,
  fetchAutoOrdersSuccess,
  fetchAutoOrdersHistorySuccess,
  updateAutoOrdersSuccess,
  deleteGroupAutoOrderSuccess,
  optimisticActivate,
  updateStart,
  updateFailed
} = slice.actions;

const apiUrl = getEnvApiUrl();

function flattenGroupAutoOrders(groupAutoOrders: GroupAutoOrder[]): AutoOrder[] {
  return groupAutoOrders.flatMap(groupAutoOrder =>
    groupAutoOrder.device_auto_orders.flatMap(deviceAutoOrder => deviceAutoOrder.alerts)
  );
}

/**
 * Update, and insert if needed, new auto orders in the current group auto orders. Does not handle deletion.
 * Deletion must be updated manually, using a different method.
 */
function patchInGroupAutoOrders(
  groupAutoOrder: GroupAutoOrder[],
  newAutoOrders: AutoOrder[]
): GroupAutoOrder[] {
  const flatAutoOrders = flattenGroupAutoOrders(groupAutoOrder);

  // Replace with new auto orders, and preserve order
  const updatedAutoOrders = flatAutoOrders
    // Replace existing
    .map(autoOrder => {
      const newAutoOrder = newAutoOrders.find(
        newAutoOrder => newAutoOrder.alert_id === autoOrder.alert_id
      );
      return newAutoOrder || autoOrder;
    })
    // Add the new ones
    .concat(
      newAutoOrders.filter(
        newAutoOrder =>
          !flatAutoOrders.some(autoOrder => autoOrder.alert_id === newAutoOrder.alert_id)
      )
    );

  return groupAutoOrdersByGroup(updatedAutoOrders);
}

export const fetchAutoOrders =
  (groupId?: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(loadStart());
      const response: AxiosResponse<{
        count: number;
        results: AutoOrderResponse[];
      }> = await axios.get(`${apiUrl.replace('v1', 'v2')}/auto-orders`, {
        params: { group_id: groupId }
      });
      await dispatch(fetchAutoOrdersSuccess(parseAutoOrderResponse(response.data.results)));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(loadFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteGroupAutoOrder =
  (groupAutoOrder: GroupAutoOrder): AppThunk =>
  async dispatch => {
    const alert_ids = groupAutoOrder.device_auto_orders.flatMap(({ alerts }) =>
      alerts.map(alert => alert.alert_id)
    );

    try {
      dispatch(updateStart());
      await axios.delete(`${apiUrl.replace('v1', 'v2')}/auto-orders/`, {
        data: { alert_ids }
      });
      dispatch(deleteGroupAutoOrderSuccess(groupAutoOrder.group_id));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateGroupAutoOrders =
  (
    autoOrders: Array<{
      alert_id: string;
      is_active: boolean;
      data_type: 'level_t' | 'missingWeight';
      min_value?: number;
      max_value?: number;
      custom_integration: CustomIntegration;

      group_id: string;
      device_id: string;
      notification_strategy_names: ('email' | 'push')[];
      recipients_for_notifications_ids: string[];
    }>,
    successCallback: () => void = () => {}
  ): AppThunk =>
  async dispatch => {
    if (!autoOrders.length) return;

    const group_id = autoOrders[0].group_id;
    try {
      dispatch(updateStart());
      const response: AxiosResponse<{
        count: number;
        results: AutoOrderResponse[];
      }> = await axios.patch(
        `${apiUrl.replace('v1', 'v2')}/groups/${group_id}/auto-orders`,
        autoOrders
      );

      dispatch(
        updateAutoOrdersSuccess({
          autoOrders: parseAutoOrderResponse(response.data.results),
          mode: 'replace'
        })
      );
      successCallback();
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const activateDeviceAutoOrder =
  (autoOrder: Pick<DeviceAutoOrder, 'device_reference' | 'is_active'>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      dispatch(optimisticActivate(autoOrder));
      const response: AxiosResponse<{
        count: number;
        results: AutoOrderResponse[];
      }> = await axios.patch(
        `${apiUrl.replace('v1', 'v2')}/devices/${autoOrder.device_reference}/auto-orders/activate`,
        {
          is_active: autoOrder.is_active ? 'ENABLED' : 'DISABLED'
        }
      );
      dispatch(
        updateAutoOrdersSuccess({
          autoOrders: parseAutoOrderResponse(response.data.results),
          mode: 'patch'
        })
      );
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(optimisticActivate({ ...autoOrder, is_active: !autoOrder.is_active }));
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const activateGroupAutoOrder =
  (autoOrder: Pick<GroupAutoOrder, 'group_id' | 'is_active'>): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateStart());
      dispatch(optimisticActivate(autoOrder));
      const response: AxiosResponse<{
        count: number;
        results: AutoOrderResponse[];
      }> = await axios.patch(
        `${apiUrl.replace('v1', 'v2')}/groups/${autoOrder.group_id}/auto-orders/activate`,
        {
          is_active: autoOrder.is_active ? 'ENABLED' : 'DISABLED'
        }
      );

      dispatch(
        updateAutoOrdersSuccess({
          autoOrders: parseAutoOrderResponse(response.data.results),
          mode: 'patch'
        })
      );
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(optimisticActivate({ ...autoOrder, is_active: !autoOrder.is_active }));
      dispatch(updateFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const fetchAutoOrdersHistory =
  (createdAt?: Moment, orderToSearch?: number | string): AppThunk =>
  async dispatch => {
    try {
      dispatch(loadStart());
      const params: Record<string, string | undefined> = {};
      if (createdAt) {
        params.minDate = createdAt.toISOString();
      }
      if (orderToSearch) {
        params.search = orderToSearch.toString();
      }
      const queryString = new URLSearchParams(params).toString();
      const response: AxiosResponse<{
        count: number;
        results: AutoOrderHistory[];
      }> = await axios.get(`${apiUrl.replace('v1', 'v2')}/zonda?${queryString}`, {});
      const resultsWithMaterialNo = response.data.results.map((result, i) => {
        if (!result.payload) {
          return { ...result, materialNo: null };
        }
        const materialNo = normalizeMaterialNo(
          JSON.parse(result.payload)?.['soapenv:Envelope']?.['soapenv:Body']?.[
            'ser:createCustomerOrderRequest'
          ]?.order?.orderItem?.material?.materialNo
        );
        return {
          ...result,
          materialNo: materialNo
        };
      });
      await dispatch(fetchAutoOrdersHistorySuccess(resultsWithMaterialNo));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(loadFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
