import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IRootState } from 'config/store';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IGraph } from 'shared/model/graph.model';
import { sortByLabel } from 'shared/utils/data-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import { criterionFormSeparator, IFilterCriterionDef } from './filterGraph.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    dataFilter: {
      width: '120px',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  })
);

interface IFilterCriterionProps {
  def: IFilterCriterionDef;
  graph: IGraph;
}

const FilterCriterion = (props: IFilterCriterionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const deviceContents = useSelector(({ product }: IRootState) => product.products);
  const groups = useSelector(({ group }: IRootState) => group.groups);

  const { control } = useFormContext();
  const { def } = props;

  const operators: ILabelValueOption[] = def.operators.map(item => ({
    label: t(`filter_${item}`),
    value: item
  }));

  const operatorName = `${def.key}${criterionFormSeparator}operator`;
  const valueName = `${def.key}${criterionFormSeparator}value`;

  let valuesOptions: ILabelValueOption[] = [];
  if (def.key === 'device_content_id') {
    valuesOptions = deviceContents.map(item => ({
      label: item.device_content,
      value: item.device_content
    }));
  } else if (def.key === 'group_ids') {
    valuesOptions = groups.map(item => ({ label: item.group_name, value: item.group_id }));
  }
  valuesOptions.sort(sortByLabel);

  const inputProps = props.def.valueType === 'number_positive' ? { min: 0 } : {};

  return (
    <Box className={classes.root}>
      <Box className={classes.dataFilter}>{t(props.def.i18n ? props.def.i18n : props.def.key)}</Box>
      <Box marginRight={1}>
        <FormControl size="small">
          <Controller
            control={control}
            name={operatorName}
            as={
              <Select>
                {operators.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        </FormControl>
      </Box>
      <Box flexGrow="1">
        {props.def.valueType !== 'multiselect' ? (
          <Controller
            as={TextField}
            type="number"
            name={valueName}
            control={control}
            inputProps={inputProps}
          />
        ) : (
          <MultipleAutoComplete name={valueName} options={valuesOptions} size="small" />
        )}
      </Box>
    </Box>
  );
};
export default FilterCriterion;
